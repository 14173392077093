import React, { useState } from "react";
import {
  Card,
  CardRow,
  CardFooter,
  CardColumn,
} from "carbon-react/lib/components/card";
import Typography from "carbon-react/lib/components/typography";
import Box from "carbon-react/lib/components/box";
import Button from "carbon-react/lib/components/button";
import Icon from "carbon-react/lib/components/icon";
import IconButton from "carbon-react/lib/components/icon-button";
import {
  ActionPopover,
  ActionPopoverItem,
} from "carbon-react/lib/components/action-popover";
import EditApi from "./Forms/EditApi";
import { deleteApiClient } from "../api";
import { useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmBox from "./ConfirmBox";
import { useMutation, useQueryClient } from "react-query";

function APIKeysCard(props) {
  const { developerId, applicationId } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const [isEditAppDialogOpen, setIsEditAppDialogOpen] = useState(false);
  const [editValues, setEditValues] = useState({});

  const [apiToEdit, setApiToEdit] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [displayConfirmationMessage, setDisplayConfirmationMessage] =
    useState(false);

  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    async () =>
      await deleteApiClient(
        getAccessTokenSilently,
        developerId,
        applicationId,
        apiToEdit.name
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["clients", developerId, applicationId]);
      },
    }
  );

  function hideParameter(isHidden, value) {
    if (!isHidden) return value;
  }

  const deleteApi = async () => {
    try {
      deleteMutation.mutate();
      setDisplayConfirmationMessage(false);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <EditApi
        apiToEdit={apiToEdit}
        isOpen={isEditAppDialogOpen}
        setIsOpen={(api) => setIsEditAppDialogOpen(api)}
        editValues={editValues}
      />
      <Card
        cardWidth="auto"
        spacing={0}
        px={0}
        marginLeft={0}
        marginTop={0}
        boxShadow="boxShadow300"
        hoverBoxShadow="boxShadow200"
      >
        <CardRow>
          <CardColumn align="left">
            <CardRow pt={2}>
              <Typography variant="segment-header" marginLeft={24} truncate>
                API Keys
              </Typography>{" "}
            </CardRow>
          </CardColumn>
        </CardRow>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: "16px",
            paddingTop: "4px",
            paddingBottom: "4px",
            borderTop: "1px solid #cdd6db",
          }}
        >
          {props.apisClients &&
            props.apisClients.length > 0 &&
            props.apis &&
            props.apisClients?.map((apiClient) => {
              const matchedApi = props.apis.find(
                (api) => api.name === apiClient.apiName
              );
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    minHeight: "6vh",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    setApiToEdit(matchedApi);
                    setEditValues(apiClient.clientProperties);
                    setIsEditAppDialogOpen(true);
                  }}
                >
                  <Icon type="key" fontSize="medium" color="grey" />
                  <div
                    style={{
                      marginLeft: "16px",
                    }}
                  >
                    <Typography variant="segment-subheader" m={0} truncate>
                      {matchedApi.displayName}
                    </Typography>

                    {matchedApi.parameters?.map((parameter) => {
                      if (parameter.presentation.showInCard &&  apiClient.clientProperties.find(
                        (property) => property.name === parameter.name
                      )) {
                        return (
                          <Typography m={0} color="grey" truncate>
                            {parameter.presentation.displayName}:
                            {hideParameter(
                              parameter.presentation.element.hiddenByDefault,
                              apiClient.clientProperties.find(
                                (property) => property.name === parameter.name
                              ) ? apiClient.clientProperties.find(
                                (property) => property.name === parameter.name
                              ).value : 'undefined'
                            )}
                          </Typography>
                        );
                      }
                      return null;
                    })}
                  </div>
                  <IconButton
                    aria-label="icon-button"
                    onClick={async () => {
                      setApiToEdit(matchedApi);
                      setDisplayConfirmationMessage(true);
                    }}
                    icon="delete"
                    align="right"
                    style={{ marginLeft: "auto", marginRight: "16px" }}
                  >
                    <ActionPopover>
                      <ActionPopoverItem
                        onClick={async () => {
                          setApiToEdit(matchedApi);
                          setEditValues(apiClient.clientProperties);
                          setIsEditAppDialogOpen(true);
                        }}
                        icon="edit"
                      >
                        Edit
                      </ActionPopoverItem>
                      <ActionPopoverItem
                        onClick={async () => {
                          setApiToEdit(matchedApi);
                          setDisplayConfirmationMessage(true);
                        }}
                        icon="delete"
                      >
                        Delete
                      </ActionPopoverItem>
                    </ActionPopover>{" "}
                  </IconButton>
                </div>
              );
            })}

          {(!props.apisClients || props.apisClients.length === 0) && (
            <p
              style={{
                marginLeft: "16px",
                marginRight: "16px",
              }}
            >
              No Sage API credentials have been generated for this app. Click{" "}
              <u onClick={() => props.setIsApiDialogOpen(true)}>Add API key</u>{" "}
              to get started
            </p>
          )}
        </div>

        <CardFooter px={2} py={1}>
          <Box
            width="100%"
            alignItems="center"
            display="flex"
            justifyContent="left"
            padding={0}
          >
            <Button
              mr={3}
              buttonType="tertiary"
              onClick={() => props.setIsApiDialogOpen(true)}
            >
              Create API keys
            </Button>
          </Box>
        </CardFooter>
      </Card>

      <ConfirmBox
        title="Delete API key?"
        onConfirm={deleteApi}
        onCancel={() => setDisplayConfirmationMessage(false)}
        open={displayConfirmationMessage}
        confirmButtonDestructive
        error={errorMessage}
      >
        Are you sure you want to delete this API key?
      </ConfirmBox>
    </>
  );
}

export default APIKeysCard;
